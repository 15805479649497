import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DuplicateLocation } from '../models/duplicatelocation';
import { ApplicationSetting } from '../models/applicationsetting';
import { HubConnectionDetail } from '../../../core/models/admin/hub-connections';
import { ReportDropdownItem } from '../../reports/models/report';
import { UserDeviceDetails } from '../../../core/models/admin/userdevicedetails';
import { UserMileage } from '../models/usermileage';
import { NavItem, NavOrder } from '../../../core/models/nav';
import { MenuItem } from '../../roles/models/role';

@Injectable()
export class AdminService {
    constructor(private http: HttpClient) {

    }

    requestHelp(connectionId: string) {
        return this.http.post<any>('/api/v2/help/admin/request', {connectionId: connectionId});
    }

    requestHelp2(email: string, phoneNumber: string, connectionId: string) {
        return this.http.post<any>('/api/v2/help/admin/requestOptional', {email: email, phoneNumber: phoneNumber, connectionId: connectionId});
    }

    processUserData(userIds: string[], startDate: Date, endDate: Date, overwrite: boolean, includeGoogleMileage: boolean) {
        return this.http.post<UserMileage>('/api/v2/admin/user/processuserdata', {userIds: userIds, startDate: startDate, endDate: endDate, overwrite: overwrite, includeGoogleMileage: includeGoogleMileage});
    }

    processUserActivityData(userIds: string[], startDate: Date, endDate: Date) {
        return this.http.post<UserMileage>('/api/v2/admin/user/reprocessactivity', {userIds: userIds, startDate: startDate, endDate: endDate});
    }

    processRetentionData(startDate: Date) {
        return this.http.post<boolean>('/api/v2/admin/admin/processuserRetention', {StartDate: startDate})
    }

    processAllUserData() {
        return this.http.post<UserMileage>('/api/v2/admin/user/processalluserdata', {});
    }

    getDeviceBatteryEvents(deviceId: string, date: Date) {
        return this.http.post<UserDeviceDetails>('/api/v2/admin/user/battery', {deviceId: deviceId, date: date});
    }

    getUserDropDownList() {
        return this.http.get<ReportDropdownItem[]>('/api/v2/admin/dropdown/users');
    }

    getUserDeviceDropDownList(userId: string) {
        return this.http.get<ReportDropdownItem[]>('/api/v2/admin/dropdown/userdevices/' + userId);
    }

    getDuplicateLocations() {
        return this.http.get<DuplicateLocation[]>('/api/v2/admin/locations/duplicates');
    }

    getHubConnectionDetails() {
        return this.http.get<HubConnectionDetail>('/api/v2/admin/hubdetails');
    }

    getApplicationSetting() {
        return this.http.get<ApplicationSetting[]>('/api/v2/config/settings/all');
    }

    getMenuItems() {
        return this.http.get<NavItem[]>('/api/v2/config/menu');
    }

    createMenu(menuItem: NavItem) {
        return this.http.post<NavItem>('/api/v2/config/menu/create', menuItem);
    }

    updateMenu(menuItem: NavItem) {
        return this.http.post<NavItem>('/api/v2/config/menu/update', menuItem);
    }

    deleteMenu(menuItem: NavItem) {
        return this.http.post<boolean>('/api/v2/config/menu/delete', menuItem);
    }

    syncMenuOrder(items: NavOrder[]) {
        return this.http.post<boolean>('/api/v2/config/menu/syncorder', items);
    }

    getData() {
        return this.http.get<boolean>('/api/v2/admin/dataHealthCheck');
    }

    addApplicationSetting(appSetting: ApplicationSetting) {
        return this.http.post<ApplicationSetting[]>('/api/v2/config/settings/add', appSetting);
    }

    updateApplicationSetting(appSetting: ApplicationSetting) {
        return this.http.post<ApplicationSetting[]>('/api/v2/config/settings/update', appSetting);
    }

    deleteApplicationSetting(appSetting: ApplicationSetting) {
        return this.http.post<boolean[]>('/api/v2/config/settings/delete', appSetting);
    }

    sendConfigUpdate() {
        return this.http.post<boolean>('/api/v2/admin/sendConfigUpdate', {});
    }

    remoteLogout(userId: string) {
        return this.http.post<boolean>('/api/v2/admin/remotelogout/' , {userId: userId});
    }
}
