import { TravelSegment, LocationResult } from '../../routes/admin/models/locationData';
import { User } from '../../routes/users/models/user';
import { LocationType } from '../../routes/locations/models/location';
import { MapHealthEvent } from '../../routes/admin/models/mapHealthEvent';
import { ProcessedActivitySegment, ProcessedLocation } from '../../routes/admin/models/processedTravelContainer';

export class MapMarkersHelper {
   static getTravelSegmentColour(segment: TravelSegment): string {
        const segmentAlpha = this.getTravelSegmentAlpha(segment);

        switch (segment.activityType) {
            case 'Drive':
              return 'rgba(63, 105, 170,' + segmentAlpha + ')';
            case 'Cycle':
              return 'rgba(213, 174, 65,' + segmentAlpha + ')';
            case 'OnFoot':
              return 'rgba(190, 158, 201,' + segmentAlpha + ')';
            case 'Stop':
              return 'rgba(72, 81, 103,' + segmentAlpha + ')';
            case 'Unkown':
              return 'rgba(188, 188, 190,' + segmentAlpha + ')';
            case 'Walk':
              return 'rgba(190, 158, 201,' + segmentAlpha + ')';
            case 'Run':
              return 'rgba(0, 110, 109,' + segmentAlpha + ')';
            default:
              return 'rgba(188, 188, 190,' + segmentAlpha + ')';
        }
    }

    static getTravelSegmentColour2(segment: ProcessedActivitySegment): string {
      const segmentAlpha = this.getTravelSegmentAlpha2(segment);

      switch (segment.activityType) {
          case 'Drive':
            return 'rgba(63, 105, 170,' + segmentAlpha + ')';
          case 'Cycle':
            return 'rgba(213, 174, 65,' + segmentAlpha + ')';
          case 'OnFoot':
            return 'rgba(190, 158, 201,' + segmentAlpha + ')';
          case 'Stop':
            return 'rgba(72, 81, 103,' + segmentAlpha + ')';
          case 'Unkown':
            return 'rgba(188, 188, 190,' + segmentAlpha + ')';
          case 'Walk':
            return 'rgba(190, 158, 201,' + segmentAlpha + ')';
          case 'Run':
            return 'rgba(0, 110, 109,' + segmentAlpha + ')';
          default:
            return 'rgba(188, 188, 190,' + segmentAlpha + ')';
      }
  }

    static detectIE() {
        const ua = window.navigator.userAgent;
      
        // Test values; Uncomment to check result …
      
        // IE 10
        // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';
        
        // IE 11
        // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';
        
        // Edge 12 (Spartan)
        // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';
        
        // Edge 13
        // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';
      
        const msie = ua.indexOf('MSIE ');
        if (msie > 0) {
          // IE 10 or older => return version number
          return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }
      
        const trident = ua.indexOf('Trident/');
        if (trident > 0) {
          // IE 11 => return version number
          const rv = ua.indexOf('rv:');
          return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }
      
        const edge = ua.indexOf('Edge/');
        if (edge > 0) {
          // Edge (IE 12+) => return version number
          return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        }
      
        // other browser
        return false;
    }

    static getLocationZindex(location: LocationMarker, index: number): Number {
        const locationZIndex = 100 + index;
        const locationWithCheckinZIndex = 1000 + index;
        if (location.users != null && location.users.length > 0) {
          return locationWithCheckinZIndex;
        }
    
        return locationZIndex;
    }

    private static getTravelSegmentAlpha(segment: TravelSegment): number {

        if(segment.isSelected === undefined) {
            return 0.5;
        }

        if (segment.isSelected) {
            return 1;
        } else {
            return 0.5;
        }
    } 

    private static getTravelSegmentAlpha2(segment: ProcessedActivitySegment): number {

      if(segment.isSelected === undefined) {
          return 0.5;
      }

      if (segment.isSelected) {
          return 1;
      } else {
          return 0.5;
      }
  } 

    static getLocationIconPng(location: any) {
        if (location.locationType === LocationType.Hospital) {
          return location.users != null && location.users.length !== 0 ? '/assets/images/pin-hospital.png' : '/assets/images/pin-hospital-inactive.png';
        } else if (location.locationType === LocationType.Base) {
          return location.users != null && location.users.length !== 0 ? '/assets/images/pin-base.png' : '/assets/images/pin-base-inactive.png';
        }
      
        return location.users != null && location.users.length !== 0 ? '/assets/images/pin-location.png' : '/assets/images/pin-location-inactive.png';
    }

    static getLocationIconSvg(location: any) {
        if (location.locationType === LocationType.Hospital) {
          return location.users != null && location.users.length !== 0 ? '/assets/images/pin-hospital.svg' : '/assets/images/pin-hospital-inactive.svg';
        } else if (location.locationType === LocationType.Base) {
          return location.users != null && location.users.length !== 0 ? '/assets/images/pin-base.svg' : '/assets/images/pin-base-inactive.svg';
        }
      
        return location.users != null && location.users.length !== 0 ? '/assets/images/pin-location.svg' : '/assets/images/pin-location-inactive.svg';
    }

    static getLocationIconSize(location: any): number {
        return location.users != null && location.users.length !== 0 ? 25 : 20;
    }

    static getLocationIcon(location: any, browserVersion: any) {
        if (browserVersion === false || browserVersion >= 12) {
          return {
            url: this.getLocationIconSvg(location),
            scaledSize: {
              height: this.getLocationIconSize(location),
              width: this.getLocationIconSize(location)
            },
            optimized: true
          };
        } else {
          return {
            url: this.getLocationIconPng(location),
            scaledSize: {
              height: this.getLocationIconSize(location),
              width: this.getLocationIconSize(location)
            },
            optimized: false
          };
        }
    }

    static getAccuracyIcon(accuracy: number) {
        if (accuracy <= 15) {
          return '/assets/images/pin-highaccuracy.svg';
        } else if (accuracy > 15 && accuracy <= 25) {
          return '/assets/images/pin-mediumaccuracy.svg';
        } else {
          return '/assets/images/pin-lowaccuracy.svg';
        }
    }

    static getHistoricLocationIcon(historicLocation: LocationResult) {
        return {
          url: this.getAccuracyIcon(historicLocation.accuracy),
          scaledSize: {
            height: 30,
            width: 30
          },
          optimized: true
        };
    }

    static getHistoricLocationIcon2(historicLocation: ProcessedLocation) {
      return {
        url: this.getAccuracyIcon(historicLocation.accuracy),
        scaledSize: {
          height: 30,
          width: 30
        },
        optimized: true
      };
  }


    static getHealthEventIcon(healthEvent: MapHealthEvent) {
        if (healthEvent.isDisconnectEvent) {
          return '/assets/images/pin-disconnected.svg';
        } else {
          return '/assets/images/pin-connected.svg';
        }
      }
}

export interface LocationMarker extends Location {
    users: User[];
}
